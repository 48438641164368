<template>
  <div class="section-six">
    <div class="title">众多合作伙伴的一致认可</div>
    <div class="sub-title">感谢每一位合作伙伴的信任</div>
    <div class="card-wrap">
      <!-- <div class="card" v-for="n in 8" :key="n"></div> -->

      <img
        :src="item.icon"
        alt=""
        class="card"
        :class="item.class"
        v-for="item in dataList"
        :key="item.id"
      />
    </div>
  </div>
</template>

<script>
const dataList = [
  {
    id: 1,
    icon: require('@/assets/phone/e.png'),
  },
  {
    id: 2,
    icon: require('@/assets/phone/f.png'),
  },
  {
    id: 3,
    icon: require('@/assets/phone/d.png'),
  },
  {
    id: 4,
    icon: require('@/assets/phone/e.png'),
  },
  {
    id: 5,
    icon: require('@/assets/phone/f.png'),
  },
  {
    id: 6,
    icon: require('@/assets/phone/d.png'),
  },
  {
    id: 7,
    icon: require('@/assets/phone/e.png'),
  },
  {
    id: 8,
    icon: require('@/assets/phone/f.png'),
  },
];
export default {
  data() {
    return {
      dataList,
    };
  },
};
</script>

<style lang="less" scoped>
.section-six {
  padding-top: 204px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title {
    color: #333333;
    font-size: 81.92px;
    font-weight: 600;
  }
  .sub-title {
    margin-top: 40px;
    color: rgba(51, 51, 51, 0.8);
    font-size: 61.44px;
  }
  .card-wrap {
    width: 100%;
    margin-top: 102px;
    display: flex;
    padding: 0 40px;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    .card {
      margin-top: 60px;
      margin-right: 35px;

      width: 896px;
      height: 419.84px;
      background: #ffffff;
      &:nth-child(2n) {
        margin-right: 0px;
        background: red;
      }
    }
  }
}
</style>

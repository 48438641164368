<template>
  <div class="footer">
    <div class="quick-question">立即咨询，免费试用30天！</div>
    <div class="about-hm">关于嗨喵</div>
    <div class="concat-us">联系我们</div>
    <div class="btn-wrap">
      <div class="btn">咨询热线：19512368757</div>
    </div>
    <div class="bottom">
      <img :src="require('@/assets/image/logo.png')" alt="" class="logo" />
      <div class="cp">版权：Copyright©2020-2022 上海嗨喵网络科技有限公司</div>
      <div class="cp">沪ICP备11007519号</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  background: #000;
  .quick-question {
    height: 235.52px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(51, 111, 246, 100);
    color: #ffffff;
    font-size: 81.92px;
  }
  .about-hm,
  .concat-us {
    height: 266px;
    padding: 81px 52px;
    color: #ffffff;
    font-size: 71.68px;
    border-bottom: 5px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .btn-wrap {
    height: 512px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 71.68px;

    .btn {
      width: 1551.36px;
      height: 266.24px;

      border-radius: 20.48px;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 92px;
    .logo {
      width: 302.08px;
      height: 148.48px;
    }
    .cp {
      margin-top: 30px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 25.6px;
    }
  }
}
</style>

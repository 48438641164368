<template>
  <div class="home publicVCenter">
    <top-header></top-header>
    <banner></banner>

    <section-one></section-one>
    <section-two></section-two>
    <section-three></section-three>
    <section-four></section-four>
    <section-five></section-five>
    <section-six></section-six>
    <my-footer></my-footer>
  </div>
</template>

<script>
import TopHeader from './components/top-header.vue';
import Banner from './components/banner.vue';
import MyFooter from './components/MyFooter.vue';
import SectionSix from './components/SectionSix.vue';
import SectionFive from './components/SectionFive.vue';
import SectionFour from './components/SectionFour.vue';
import SectionThree from './components/SectionThree.vue';
import SectionTwo from './components/SectionTwo.vue';
import SectionOne from './components/SectionOne.vue';

export default {
  name: 'phoneHome',
  metaInfo: {
    title: '婚礼堂悦动 - 婚礼堂悦动登录_婚礼堂悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '婚礼堂悦动,婚礼堂悦动大屏互动,婚礼堂悦动官网,婚礼堂悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '婚礼堂悦动是一款免费,简单,好玩,个性化的微信墙,微信大屏幕制作软件工具。婚礼堂悦动,婚礼堂悦动大屏互动,婚礼堂悦动官网,婚礼堂悦动登录,适用于年会,酒吧,婚礼婚庆,校园活动,培训,演出,订货会,开盘等活动方案策划,包括微信上墙,弹幕,签到,抽奖,投票等现场必备功能,更有摇一摇,赛马,红包雨,打赏,霸屏,数钱等有趣的现场互动大屏幕游戏,支持微信小程序互动,功能免费用,活动零成本,现场互动营销效果佳,微信增粉快。',
      },
    ],
  },
  components: {
    TopHeader,
    Banner,
    MyFooter,
    SectionSix,
    SectionFive,
    SectionFour,
    SectionThree,
    SectionTwo,
    SectionOne,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    toRegister() {
      // this.$router.push({
      //   path: '/phoneRegister',
      // });
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f3f6fa;
}
</style>

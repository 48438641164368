<template>
  <div class="section-six">
    <div class="title">获客效果说明（单店年度数据）</div>
    <div class="sub-title">婚礼堂接入获客系统后，场均婚礼获客2.36单</div>
    <div class="card-wrap">
      <div class="card" v-for="item in dataList" :key="item.id">
        <!-- <div class="card-img"></div> -->
        <img :src="item.icon" alt="" class="card-img" :class="item.class" />
        <div class="card-body">
          <div class="card-body__li1">{{ item.d1 }}</div>
          <div class="card-body__li2">{{ item.d2 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const dataList = [
  {
    id: 1,
    d1: '·单店年度吸粉 ',
    d2: '·店铺公众号吸粉再也不担心',
    icon: require('@/assets/phone/a.png'),
    class: 'a',
  },
  {
    id: 2,
    d1: '·客户咨询量 8000+  ',
    d2: '·每年上万条客户线索，数千个客户咨询',
    icon: require('@/assets/phone/b.png'),
    class: 'b',
  },
  {
    id: 3,
    d1: '·成交订单数 ',
    d2: '·帮助店铺年盈利增长数千万',
    icon: require('@/assets/phone/c.png'),
    class: 'c',
  },
];
export default {
  data() {
    return {
      dataList,
    };
  },
};
</script>

<style lang="less" scoped>
.section-six {
  margin: 0 auto;
  margin-bottom: 60px;
  width: 1920px;
  // height: 4198.4px;
  // background: #ffffff;
  padding-top: 143px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  .title {
    color: #333333;
    font-size: 81.92px;
    font-weight: 600;
  }
  .sub-title {
    margin-top: 40px;
    color: rgba(51, 51, 51, 0.8);
    font-size: 61.44px;
  }
  .card-wrap {
    margin: 0 auto;

    width: 100%;
    margin-top: 102px;
    display: flex;
    padding: 0 40px;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .card {
      margin-top: 60px;
      // width: 1745.92px;
      // height: 619.52px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 20.48px;
      // backdrop-filter: blur(51.2px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 80px;
      .card-img {
        width: 337.92px;
        height: 337.92px;
        // background: blue;
        &.a {
          width: 337.92px;
          height: 337.92px;
        }
        &.b {
          width: 327.68px;
          height: 337.92px;
        }
        &.c {
          width: 337.92px;
          height: 337.92px;
        }
      }
      &-title {
        font-size: 81.92px;
        color: #fff;
      }
      &-body {
        margin-top: 60px;
        text-align: center;
        &__li1 {
          color: rgba(51, 51, 51, 1);
          font-size: 71.68px;
        }
        &__li2 {
          color: rgba(51, 51, 51, 1);
          font-size: 71.68px;
        }
      }
      .card-desc {
        height: 209px;
        background: #f3f6fa;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;
        font-size: 61.44px;
      }
    }
  }
}
</style>

<template>
  <div class="section-six">
    <div class="title">嗨喵婚礼堂获客系统</div>
    <div class="sub-title">四大功能实现轻松获客</div>
    <div class="card-body-wrap">
      <div class="card-wrap">
        <div class="card" v-for="(item) in dataList" :key="item.id">
          <div class="header">{{item.d1}}</div>
          <img :src="item.icon" alt="" class="card-img" :class="item.class" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const dataList = [
  {
    id: 1,
    d1: '1.大屏互动聚集现场流量 ',
    icon: require('@/assets/phone/d.png'),
    class: 'a',
  },
  {
    id: 2,
    d1: '2.大屏互动聚集现场流量  ',
    icon: require('@/assets/phone/d.png'),
    class: 'b',
  },
  {
    id: 3,
    d1: '3.大屏互动聚集现场流量 ',
    icon: require('@/assets/phone/d.png'),
    class: 'c',
  },
  {
    id: 4,
    d1: '4.大屏互动聚集现场流量 ',
    icon: require('@/assets/phone/d.png'),
    class: 'c',
  },
];
export default {
  data() {
    return {
      dataList,
    };
  },
};
</script>

<style lang="less" scoped>
.section-six {
  padding-top: 144px;
  padding-bottom: 102px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 1827.84px;
  height: 1592.32px;
  background: #ffffff;
  margin: 0 auto;
  background: #fff;
  .title {
    color: #333333;
    font-size: 81.92px;
    font-weight: 600;
  }
  .sub-title {
    margin-top: 40px;
    color: rgba(51, 51, 51, 0.8);
    font-size: 61.44px;
  }
  .card-body-wrap {
    width: 100vw;
    // height: 712px;
    overflow-x: scroll;
  }
  .card-wrap {
    width: 100%;
    margin-top: 102px;
    display: flex;
    padding: 40px 300px;
    padding-right: 700px;
    // flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    .card {
      .header {
        width: 1182.72px;
        height: 225.28px;
        background: linear-gradient(101deg, #336ff6, #56b5f7 100%);
        font-size: 71.68px;

        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      margin-top: 60px;
      margin-right: 76px;
      flex-shrink: 0;
      .card-img {
        margin-top: 64px;
        width: 1182.72px;
        height: 757.76px;
        // background: red;
        // &:nth-child(2n) {
        // margin-right: 0px;
        // background: red;
        // }
      }
    }
  }
}
</style>

<template>
  <div class="section-six">
    <div class="title">全方位运营护航</div>
    <div class="sub-title">500+嗨喵运营团队给予客户最大的运营与技术支持</div>
    <div class="card-wrap">
      <div class="card" v-for="item in dataList" :key="item.id">
        <!-- <img src="" alt="" class="card-img" /> -->
        <img :src="item.icon" alt="" class="card-img" :class="item.class" />

        <div class="card-desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const dataList = [
  {
    id: 1,
    desc: '专业的客户培训团队',
    icon: require('@/assets/phone/i.png'),
    class: 'a',
  },
  {
    id: 2,
    desc: '强大的技术保障',
    icon: require('@/assets/phone/j.png'),
    class: 'b',
  },
  {
    id: 3,
    desc: '7*24小时客服支持',
    icon: require('@/assets/phone/k.png'),
    class: 'c',
  },
];
export default {
  data() {
    return {
      dataList,
    };
  },
};
</script>

<style lang="less" scoped>
.section-six {
  margin: 0 auto;
  width: 1827.84px;
  background: #ffffff;
  padding-top: 110px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 60px;

  .title {
    color: #333333;
    font-size: 81.92px;
    font-weight: 600;
  }
  .sub-title {
    margin-top: 40px;
    color: rgba(51, 51, 51, 0.8);
    font-size: 61.44px;
  }
  .card-wrap {
    margin: 0 auto;

    width: 100%;
    margin-top: 102px;
    display: flex;
    padding: 0 40px;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .card {
      width: 552.96px;
      height: 430.08px;
      background: #ffffff;
      border-radius: 10.24px;
      box-shadow: 0px 6.19px 13.93px 0px rgba(86, 164, 255, 0.16);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .card-img {
        width: 143.36px;
        height: 184.32px;
        // background: blue;
        &.a {
          width: 143.36px;
          height: 184.32px;
        }
        &.b {
          width: 143.36px;
          height: 133.12px;
          margin-bottom: 51px;
        }
        &.c {
          width: 128px;
          height: 133.12px;
          margin-bottom: 51px;

        }
      }
      .card-desc {
        margin-top: 77px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;
        font-size: 44.2px;
      }
    }
  }
}
</style>

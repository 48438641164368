<template>
  <div class="section-six">
    <div class="title">产品优势</div>
    <div class="sub-title">功能强大丰富，赋能婚礼堂增长获客</div>
    <div class="card-wrap">
      <div class="card" v-for="item in dataList" :key="item.id">
        <div class="card-title">{{ item.title }}</div>
        <div class="card-body">
          <div class="card-body__li">{{ item.d1 }}</div>
          <div class="card-body__li">{{ item.d2 }}</div>
          <div class="card-body__li">{{ item.d3 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const dataList = [
  {
    id: 1,
    title: '行业首创，专为婚礼堂量身打造',
    d1: '·大屏互动+私域流量+智能营销 ',
    d2: '·嗨喵科技历经十数次产品迭代',
    d3: '·开辟婚礼堂线上获客新渠道',
  },
  {
    id: 2,
    title: '会员获取率高',
    d1: '·大屏互动现场气氛热烈，人人参与互动  ',
    d2: '·会员转化率高达95%',
    d3: '·轻松构建私域流量池',
  },
  {
    id: 3,
    title: '数字化智能运营获客',
    d1: '·AI智能筛选意向客户 ',
    d2: '·用户行为、停留时长全展现',
    d3: '·帮助管理人员挖掘更多客户价值',
  },
];
export default {
  data() {
    return {
      dataList,
    };
  },
};
</script>

<style lang="less" scoped>
.section-six {
  margin: 0 auto;
  width: 1920px;
  // height: 4198.4px;
  // background: #ffffff;
  padding-top: 143px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #000;
  .title {
    color: #fff;
    font-size: 81.92px;
    font-weight: 600;
  }
  .sub-title {
    margin-top: 40px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 61.44px;
  }
  .card-wrap {
    margin: 0 auto;

    width: 100%;
    margin-top: 102px;
    display: flex;
    padding: 0 40px;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .card {
      margin-top: 60px;
      width: 1745.92px;
      height: 619.52px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 20.48px;
      backdrop-filter: blur(51.2px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      padding: 80px;
      .card-img {
        width: 1740.8px;
        height: 952.32px;
        background: blue;
      }
      &-title {
        font-size: 81.92px;
        color: #fff;
      }
      &-body {
        margin-top: 60px;
        &__li {
          color: #ffffff;
          font-size: 61.44px;
        }
      }
      .card-desc {
        height: 209px;
        background: #f3f6fa;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;
        font-size: 61.44px;
      }
    }
  }
}
</style>

<template>
  <div class="section-six">
    <div class="title">成功案例</div>
    <div class="sub-title">500+婚礼堂酒店选择嗨喵</div>
    <div class="card-wrap">
      <div class="card" v-for="item in dataList" :key="item.id">
        <!-- <img src="" alt="" class="card-img" /> -->
        <img :src="item.icon" alt="" class="card-img" :class="item.class" />

        <div class="card-desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const dataList = [
  {
    id: 1,
    desc: 'A股上市公司同庆楼签约嗨喵，第一次婚宴现场即获客',
    icon: require('@/assets/phone/e.png'),

  },
  {
    id: 2,
    desc: '常州18Hall婚礼堂接入嗨喵系统，线上咨询量源源不断',
    icon: require('@/assets/phone/f.png'),

  },
  {
    id: 3,
    desc: '南昌云境婚礼堂4店同时使用嗨喵，获客已破百单',
    icon: require('@/assets/phone/d.png'),

  },
];
export default {
  data() {
    return {
      dataList,
    };
  },
};
</script>

<style lang="less" scoped>
.section-six {
  margin: 0 auto;
  width: 1827.84px;
  // height: 4198.4px;
  background: #ffffff;
  padding-top: 204px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title {
    color: #333333;
    font-size: 81.92px;
    font-weight: 600;
  }
  .sub-title {
    margin-top: 40px;
    color: rgba(51, 51, 51, 0.8);
    font-size: 61.44px;
  }
  .card-wrap {
    margin: 0 auto;

    width: 100%;
    margin-top: 102px;
    display: flex;
    padding: 0 40px;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .card {
      margin-top: 60px;
      // margin-right: 35px;

      width: 1745.92px;
      height: 1162.24px;
      background: #f3f6fa;
      border-radius: 10.24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .card-img {
        width: 1740.8px;
        height: 952.32px;
        background: blue;
      }
      .card-desc {
        height: 209px;
        background: #f3f6fa;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;
        font-size: 61.44px;
      }
    }
  }
}
</style>
